<section id="about" itemscope itemtype="https://schema.org/AboutPage">
    <div class="about-container" itemscope itemtype="https://schema.org/Person">
        <!-- structured data properties -->
        <meta itemprop="jobTitle" content="Full-stack Java Developer" />
        <meta itemprop="knowsLanguage" content="en" />
        <meta itemprop="knowsLanguage" content="pt" />
        <meta itemprop="nationality" content="Brazil" />
        <meta itemprop="gender" content="Male" />
        <meta itemprop="email" content="prateek.technocrat@gmail.com" />
        <meta itemprop="birthDate" content="1993-06-29" />
        <meta itemprop="url" content="https://www.guilhermeborgesbastos.com" />
        <div class="first-column">
            <h1 i18n="nav@@aboutMe">About me</h1>
            <h2><span itemprop="name">{{ name }}</span></h2>
            <p class="years-old">
                <span itemprop="yearsOld">{{ yearsOld }}</span>
                &nbsp;<span i18n="about@@yearsOld">years old</span>
            </p>
            <p class="text" internationalization [data]="aboutData?.internationalizations" property='description' itemprop="summary">Loading...</p>
            <div class="hobbies">
                <h3>Interests</h3>
                <fa-icon *ngFor="let hobby of aboutData?.hobbies" [icon]="['fas', hobby.icon]" class="icon" title="{{ hobby.title }}" itemprop="hobby"></fa-icon>
            </div>
        </div>
        <div class="second-column">
            <div class="profile-picture"></div>
            <div class="social-media">
                <a *ngFor="let media of aboutData?.medias" href="{{ media.http }}" target="_blank" itemprop="sameAs">
                    <fa-icon [icon]="['fab', media.icon]" class="icon" title="{{ media.title }}"></fa-icon>
                </a>
            </div>
        </div>
    </div>
</section>