<header itemscope itemtype="https://schema.org/WPHeader">

    <div class="child navbar-toggle">
        <fa-icon [icon]="faBars" class="bar-icon" (click)="onToggleBar()"></fa-icon>
    </div>

    <div class="child logo-container" itemprop="brand" itemscope itemtype="https://schema.org/Brand">
        <a (click)="resetMenu()" href="#" class="logo" itemprop="name" itemprop="logo">prateek kumar</a>
    </div>

    <div class="child nav-container" #nav [hidden]="!hasMenuToggled" itemscope itemtype="https://schema.org/SiteNavigationElement">
        <ul>
            <li><a (click)="resetMenu()" href="#about" i18n="nav@@aboutMe" itemprop="url"><span itemprop="name">About me</span></a></li>
            <li><a (click)="resetMenu()" href="#experience" i18n="nav@@experiences" itemprop="url"><span itemprop="name">Experiences</span></a></li>
            <li><a (click)="resetMenu()" href="#posts" i18n="nav@@posts" itemprop="url"><span itemprop="name">Certifications</span></a></li>
            <li><a (click)="resetMenu()" href="#contact" i18n="nav@@contact" itemprop="url"><span itemprop="name">Contact</span></a></li>
            <li><a (click)="resetMenu()" href="https://www.dropbox.com/s/g7yhmskjjc3by7x/Resume%20-%20Prateek.pdf?dl=0" i18n-title="nav@@open.as.pdf" title="Open Resume as PDF" target="_blank"><fa-icon [icon]="faCloudDownloadAlt" class="bar-icon"></fa-icon></a></li>
        </ul>
    </div>

    <!--<div class="child language-container">
        <div class="frame">
            <ul>
                <li itemprop="availableLanguage" itemscope itemtype="https://schema.org/Language">
                    <a href="/en" [ngClass]="{'active': locale === 'en'}" itemprop="name">EN</a>
                </li>
                <li itemprop="availableLanguage" itemscope itemtype="https://schema.org/Language">
                    <a href="/pt" [ngClass]="{'active': locale === 'pt'}" itemprop="name">PT</a>
                </li>
            </ul>
        </div>
    </div>-->

    <div class="child share-container" #shareBtn>
        <fa-icon [icon]="faShareAlt" class="icon" (click)="share()" alt="share" itemprop="share"></fa-icon>
    </div>

</header>   
