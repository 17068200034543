<div class="dialog-container">
    <img loading="lazy" src="./assets/template/welcome/bubble-frame.png" alt="Welcome Speech Bobble"/>
    <div class="console">
        <p class="hello" i18n="dialog@@hello">Hi There!</p>
        <span
            class="txt-rotate" 
            typingAnimation
            [phrasePeriod]="2000"
            [startDelay]="1500"
            [typeSpeed]="200"
            [data]='[
                { 
                    "language": "en",
                    "phrases": [ "My name is Prateek.", "Thanks for dropping by!", "Down below, you will know me better... :)" ]
                }
            ]'></span>
    </div>
</div>