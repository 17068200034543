<section id="experience" class="landscape" [style.backgroundImage]="'url('+backgroundUrl+')'">
    <div class="overlay">
        <div class="container">
            <div class="top-container">
                <div class="title">
                    <h1 i18n="nav@@experiences">Experiences</h1>
                </div>
                <div class="navigation">
                    <a href="javascript:void(0)" class="previous" (click)="onClickPrevious()" [ngClass]="{'disabled': disablePreviousNavigation()}">
                        <fa-icon [icon]="['fas', 'chevron-left']" class="icon" i18n-title="exp.nav@@previous" title="Previous"></fa-icon>
                        <span i18n="exp.nav@@previous">Previous</span>
                    </a>
                    <div class="devider">|</div>
                    <a href="javascript:void(0)" class="next" (click)="onClickNext()" [ngClass]="{'disabled': disableNextNavigation()}">
                        <span i18n="exp.nav@@next">Next</span>
                        <fa-icon [icon]="['fas', 'chevron-right']" class="icon" i18n-title="exp.nav@@next" title="Next"></fa-icon>
                    </a>
                </div>

                <!-- Custom mobile menu active under 600px wide resolution -->
                <div class="navigation-mobile">
                    <a href="javascript:void(0)" class="previous" (click)="onClickPrevious()" [ngClass]="{'disabled': disablePreviousNavigation()}">
                       <div class="molding">
                           <span [innerHtml]="previousYear | safariDateFormatter | localizedDate:'yyyy'"></span>
                           <fa-icon [icon]="['fas', 'chevron-left']" class="icon" i18n-title="exp.nav@@previous" title="Previous"></fa-icon>
                        </div>
                    </a>
                    <div class="current"><span [innerHtml]="currentYear | safariDateFormatter | localizedDate:'yyyy'"></span></div>
                    <a href="javascript:void(0)" class="next" (click)="onClickNext()" [ngClass]="{'disabled': disableNextNavigation()}">
                        <div class="molding">
                            <fa-icon [icon]="['fas', 'chevron-right']" class="icon" i18n-title="exp.nav@@next" title="Next"></fa-icon>
                            <span [innerHtml]="nextYear | safariDateFormatter | localizedDate:'yyyy'"></span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="middle-container" (swipe)="onSwipe($event)">
                <div class="events-content">
                    <ol #orderedList>
                        <li *ngFor="let exp of experiencesOrdered; let i = index" [ngClass]="{'selected': i == 0}" data-id="{{ exp.id }}">
                            <div class="header-block">
                                <div class="logo-block">
                                    <a href="{{ exp.website }}" target="_blank"><img src="{{ exp.logo }}" alt="{{ exp.companyName }}"></a>
                                </div>
                                <div class="info-block">
                                    <div class="company-name"><a href="{{ exp.website }}" target="_blank">{{ exp.companyName }}</a></div>
                                    <div class="role"  internationalization [data]="exp.internationalizations" property='role'>Loading...</div>
                                    <div class="period">
                                        <span>{{ exp.startAt | safariDateFormatter | localizedDate:'MMM yyyy' }}</span>
                                        <span class="devider">-</span>
                                        <span>{{ (exp.endAt | safariDateFormatter | localizedDate:'MMM yyyy') }}</span>
                                    </div>
                                    <div class="location">
                                        <span class="city" internationalization [data]="exp.internationalizations" property='city'>Loading...</span>
                                        <span class="devider">,&nbsp;</span>
                                        <span class="country" internationalization [data]="exp.internationalizations" property='country'>Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <div class="description" internationalization [data]="exp.internationalizations" property='description'>Loading...</div>
                            <div class="technologies">
                                <span class="hashtag" *ngFor="let technology of exp.technologies">{{ technology }}</span>
                            </div>
                            <div class="social-media">
                                <a *ngFor="let media of exp.medias" href="{{ media.http }}" target="_blank">
                                    <fa-icon [icon]="['fab', media.icon]" class="icon" title="{{ media.title }}"></fa-icon>
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>

            <app-experience-timeline 
                [experiences]="experiences" 
                [currentPosition]="currentPosition" 
                (onTimelineChanged)="updateNavigation($event)"></app-experience-timeline>
        </div>
    </div>
</section>
