<div class="page-not-found-text">
    <p>404</p>
</div>
<div class="container">
    <!-- caveman left -->
    <div class="caveman">
        <div class="leg">
            <div class="foot">
                <div class="fingers"></div>
            </div>      
        </div>
        <div class="leg">
            <div class="foot">
                <div class="fingers"></div>
            </div>      
        </div>
        <div class="shape">
            <div class="circle"></div>
            <div class="circle"></div>
        </div>
        <div class="head">
            <div class="eye">
                <div class="nose"></div>
            </div>
            <div class="mouth"></div>
        </div>
        <div class="arm-right">
            <div class="club"></div>
        </div>    
    </div>
    <!-- caveman right -->
    <div class="caveman">
    <div class="leg">
        <div class="foot">
            <div class="fingers"></div>
        </div>
    </div>
    <div class="leg">
        <div class="foot">
            <div class="fingers"></div>
        </div>
    </div>
    <div class="shape">
        <div class="circle"></div>
        <div class="circle"></div>
    </div>
    <div class="head">
        <div class="eye"><div class="nose"></div></div>
        <div class="mouth"></div>
    </div>
    <div class="arm-right">
        <div class="club"></div>
    </div>    
    </div>
</div>

<a href="/">Go back to Home Page.</a>