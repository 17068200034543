<div class="skills">
    <img loading="lazy" class="background" src="/assets/template/welcome/illustration-back.png" alt="Background circle"/>
    <img loading="lazy" class="skill-icon first" src="/assets/template/welcome/icons/angular.png" alt="Angular"/>
    <img loading="lazy" class="skill-icon second" src="/assets/template/welcome/icons/html.png" alt="HTML"/>
    <img loading="lazy" class="skill-icon third" src="/assets/template/welcome/icons/git.png" alt="Git"/>
    <img loading="lazy" class="skill-icon fourth" src="/assets/template/welcome/icons/java.png" alt="JAVA"/>
    <img loading="lazy" class="skill-icon fifth" src="/assets/template/welcome/icons/react.png" alt="React"/>
    <img loading="lazy" class="skill-icon sixth" src="/assets/template/welcome/icons/aws.png" alt="AWS"/>
    <img loading="lazy" class="skill-icon seventh" src="/assets/template/welcome/icons/nodejs.png" alt="NODEJS"/>
    <img loading="lazy" class="skill-icon eighth" src="/assets/template/welcome/icons/dynamo.png" alt="Dynamo"/>
    <img loading="lazy" class="skill-icon ninth" src="/assets/template/welcome/icons/android.png" alt="Android"/>
    <img loading="lazy" class="skill-icon tenth" src="/assets/template/welcome/icons/serverless.png" alt="Serverless"/>
    <img loading="lazy" class="skill-icon eleventh" src="/assets/template/welcome/icons/json.png" alt="JSON"/>
    <img loading="lazy" class="skill-icon twelth" src="/assets/template/welcome/icons/typescript.png" alt="JSON"/>
</div>