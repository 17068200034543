import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-page-not-found",
    templateUrl: "./page-not-found.html",
    styleUrls: ["./page-not-found.component.scss", "./page-not-found.component.responsivity.scss"]
})

export class PageNotFoundComponent implements OnInit {
    
    constructor() {}

    ngOnInit(): void {}
}