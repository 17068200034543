<section id="contact" itemscope itemtype="https://schema.org/ContactPage">
    <div class="feedback-container" [ngClass]="feedbackStatus">
        <p class="success">
            <span i18n="contact@@successfulSubmit">Thank you for contacting me, I'll reply to you very soon.</span>
            <fa-icon [icon]="faTimes" class="icon" title="Close" (click)="closeFeedbackMessage()"></fa-icon>
        </p>
        <p class="error">
            <span i18n="contact@@errorSubmit">Sorry, there is an instability, try again later, or contact me via email or social media.</span>
            <fa-icon [icon]="faTimes" class="icon" title="Close" (click)="closeFeedbackMessage()"></fa-icon>
        </p>
    </div>
    <div class="container" [ngClass]="{'fade': hasBeenSubmited}">
        <div class="left-side">
            <div class="title">
                <h1 i18n="nav@@contact">Contact</h1>
            </div>
            <div class="top-container">
                <div class="picture"></div>
                <div class="info">
                    <p class="name" *ngIf="name"><span itemprop="name">{{ name }}</span></p>
                    <p class="email" *ngIf="email"><fa-icon [icon]="faEnvelope" class="icon" title="Email"></fa-icon><a  href="mailto:{{ email }}" itemprop="email">{{ email }}</a></p>
                    <p class="phone" *ngIf="phone"><fa-icon [icon]="faPhone" class="icon" title="Phone"></fa-icon><span itemprop="telephone">{{ phone }}</span></p>
                    <p class="location" *ngIf="location"><fa-icon [icon]="faMapMarkerAlt" class="icon" title="City"></fa-icon><span itemprop="city">{{ location }}</span></p>
                </div>
            </div>
            <div class="bottom-container">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit(contactNgForm)" #contactNgForm="ngForm" [ngClass]="{'loading': isLoading}">
                    <div class="form-group">
                        <label for="name" i18n="contact@@name">Your name:</label>
                        <input [class.is-invalid]="senderName.invalid && senderName.touched"
                            type="text" name="name" i18n-placeholder="contact@@namePlaceholder" placeholder="Mark" formControlName="name" >
                        <div class="warnings" *ngIf="(senderName.invalid && senderName.touched) || senderName.dirty">
                            <!--question mark(?) is a safe navigation operator-->
                            <small *ngIf="senderName.errors?.required" class="text-danger" i18n="contact@@requiredNameError">The name is required.</small>
                            <small *ngIf="senderName.errors?.pattern" class="text-danger" i18n="contact@@invalidNameError">Please, provide a valid name.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email" i18n="contact@@email">Email:</label>
                        <input [class.is-invalid]="senderEmail.invalid && senderEmail.touched"
                            type="email" name="email" i18n-placeholder="contact@@emailPlaceholder" placeholder="mark@gmail.com" formControlName="email" required>
                        <div class="warnings" *ngIf="(senderEmail.invalid && senderEmail.touched) || senderEmail.dirty">
                            <!--question mark(?) is a safe navigation operator-->
                            <small *ngIf="senderEmail.errors?.required" class="text-danger" i18n="contact@@requiredEmailError">The email is required.</small>
                            <small *ngIf="senderEmail.errors?.pattern" class="text-danger" i18n="contact@@invalidEmailError">Please, provide a valid email address.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="message" i18n="contact@@message">Your message:</label>
                        <textarea [class.is-invalid]="senderMessage.invalid && senderMessage.touched"
                            name="message" i18n-placeholder="contact@@messagePlaceholder" placeholder="Write here your message... I will reply to you soon." formControlName="message" required></textarea>
                        <div class="warnings" *ngIf="(senderMessage.invalid && senderMessage.touched) || senderMessage.dirty">
                            <!--question mark(?) is a safe navigation operator-->
                            <small *ngIf="senderMessage.errors?.required" class="text-danger" i18n="contact@@requiredMessageError">The message is required.</small>
                        </div>
                    </div>
                    <input type="submit" i18n-value="contact@@send" value="Send">                
                </form>
            </div>
        </div>
        <div class="right-side"></div>
    </div>
    <img loading="lazy" height="705" src="./assets/template/contact/gbastos-Illustration.png" class="illustration" alt="illustration" itemprop="avatar">
    <div class="half-circle"></div>
</section>
