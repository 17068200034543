export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDOXEHe597jmYUgYocwsXEmA6Zf19_9rWA",
    authDomain: "prateekkumar-2d77c.firebaseapp.com",
    projectId: "prateekkumar-2d77c",
    storageBucket: "prateekkumar-2d77c.appspot.com",
    messagingSenderId: "818538196860",
    appId: "1:818538196860:web:bb2d41c35a6d565f7ebef9",
    measurementId: "G-FK87BJBN8E"
  }
};
