import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-welcome-background",
    templateUrl: "./welcome-background.component.html",
    styleUrls: [ "./welcome-background.scss", "./welcome-background.reponsivity.scss" ]
})

export class WelcomeBackgroundComponent implements OnInit {
    
    constructor() {}

    ngOnInit() {}

}